import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DotsLoaderView, ModalView, RatingStars } from "..";
import {
  BlockIcon,
  DeleteIcon,
  DropDownIcon,
  DropupIcon,
  EditIcon,
  ReplyIcon,
  SortingArrow,
  UnblockIcon,
  article,
  calender,
  cancelReason,
} from "../../../assets/images";
import { SORTING } from "../../../helpers";
import { titleCase } from "../../../utils";
import { sortingFilterType } from "../../Types";
import { modalTypes } from "./config";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  Tooltip,
} from "@mui/material";
import ShowCancelReasonModal from "../../../pages/coaching-sessions/modules/show-cancel-reason";

interface Props {
  actions: boolean;
  showInvoiceHeading?: string;
  showInvoiceIcon?: boolean;
  expandedRows: Array<any>;
  actionConfig?: any;
  isLoading: boolean;
  isShowColumns: boolean;
  isTableLoading: boolean;
  columns: Array<any>;
  popupHeading: string;
  replyComponent: React.FC<any>;
  cancelSessionComponent: React.FC<any>;
  expandedComponent: React.FC<any>;
  recordId: string;
  isEditAction: boolean;
  isReplyAction: boolean;
  isMsgAction: boolean;
  isCancelAction: boolean;
  isCalenderAction: boolean;
  isBlockUnblockAction: boolean;
  onCalendarActionClick: any;
  list: Array<any>;
  editComponent: React.FC<any>;
  sortingFilters: sortingFilterType;
  openedModalType: string;
  onDeleteSubmit: () => void;
  onToggleModal: (type?: string, rid?: string) => void;
  onGetStatusClassName: (status: string) => void;
  onBlockSubmit: () => void;
  onUnBlockSubmit: () => void;
  onSorting: (filters: sortingFilterType) => void;
  onExpandRow: (rowIndex: number) => void;
  Status?: JSX.Element;
  setCoachId?: any;
  selectedTab?: string;
  setSessionData?: any;
  isShowCancelReason?: boolean;
  sessionData?: any;
  showToolTip?: boolean;
}

interface SortingIconsProps {
  name: string;
  sorting: boolean;
  filters: sortingFilterType;
}

function AscIcon() {
  return (
    <img src={SortingArrow} style={{ transform: "rotate(180deg)" }} alt="Asc" />
  );
}

function DescIcon() {
  return <img src={SortingArrow} alt="Desc" />;
}

function SortingIcons({ name, sorting, filters }: SortingIconsProps) {
  if (!sorting) {
    return null;
  }

  return (
    <div className="sort-arrow">
      {filters.sortBy && filters.sortOrder && filters.sortBy === name ? (
        <>
          {filters.sortOrder === SORTING.ASC && <AscIcon />}
          {filters.sortOrder === SORTING.DESC && <DescIcon />}
        </>
      ) : (
        <>
          <AscIcon />
          <DescIcon />
        </>
      )}
    </div>
  );
}

function TableViewComponent({
  onCalendarActionClick,
  Status,
  actions,
  showInvoiceHeading,
  showInvoiceIcon,
  expandedRows,
  actionConfig,
  columns,
  recordId,
  isReplyAction,
  isEditAction,
  isMsgAction,
  isCancelAction,
  isShowCancelReason,
  isCalenderAction,
  isShowColumns,
  isBlockUnblockAction,
  popupHeading,
  replyComponent: ReplyComponent,
  cancelSessionComponent: CancelSessionComponent,
  expandedComponent: ExpandedComponent,
  isLoading,
  isTableLoading,
  openedModalType,
  list,
  editComponent: EditComponent,
  sortingFilters,
  onDeleteSubmit,
  onToggleModal,
  onGetStatusClassName,
  onBlockSubmit,
  onUnBlockSubmit,
  onSorting,
  onExpandRow,
  setCoachId,
  selectedTab,
  sessionData,
  setSessionData,
  showToolTip,
}: Props) {
  const navigate = useNavigate();
  const capitalizeHeading = React.useMemo(
    () => titleCase(popupHeading),
    [popupHeading]
  );

  const [queries, setQueries] = useState<any>();

  const [open, setOpen] = React.useState(false);
  const [isCancelReason, setIsCancelReason] = React.useState<any>(false);
  const [reason, setReason] = React.useState<any>();
  const [type, setType] = useState<any>();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="table-wrapper">
        <table>
          {isShowColumns && (
            <thead>
              <tr>
                {columns.map((col, index) => {
                  const { name, sorting = false, key } = col;
                  return (
                    <td
                      key={`col-${index}`}
                      className={sorting ? "hand-icon" : ""}
                      onClick={() =>
                        sorting &&
                        onSorting({
                          sortBy: key,
                          sortOrder:
                            sortingFilters.sortOrder === SORTING.DESC
                              ? SORTING.ASC
                              : SORTING.DESC,
                        })
                      }
                    >
                      {name}
                      <SortingIcons
                        name={key}
                        sorting={sorting}
                        filters={sortingFilters}
                      />
                    </td>
                  );
                })}
                {showInvoiceIcon && (
                  <td key={`col-${columns.length}`}>{showInvoiceHeading}</td>
                )}
                {actions && (
                  <td key={`col-${columns.length}`}>{actionConfig.colName}</td>
                )}
              </tr>
            </thead>
          )}
          <tbody>
            {list?.map((item, i) => {
              const isExpanded = expandedRows.indexOf(i) > -1;

              return (
                <>
                  <tr key={`row-${i}`}>
                    {/* {Status} */}

                    {columns.map((row, j) => {
                      const {
                        key,
                        link = "",
                        title = "",
                        redirection = false,
                        button = false,
                        rating = false,
                        bgColors = {},
                        bold = false,
                      } = row;
                      // console.log(row, "item");
                      const value =
                        item[key] === undefined || item[key] === ""
                          ? "-"
                          : item[key];
                      const itemProps: any = { key: `row-item-${i}.${j}` };

                      if (bold) {
                        itemProps.style = { fontWeight: "bold" };
                      }

                      if (!!link) {
                        return (
                          <td {...itemProps}>
                            <Link to={`${link}/${item.id}`}>
                              <Tooltip
                                title={
                                  showToolTip && item.programName
                                    ? item.programName
                                    : ""
                                }
                              >
                                {value}
                              </Tooltip>
                            </Link>
                          </td>
                        );
                      }

                      if (redirection && value !== "-") {
                        return (
                          <td {...itemProps}>
                            <Link
                              to="#"
                              onClick={() => window.open(value, "_blank")}
                            >
                              {title}
                            </Link>
                          </td>
                        );
                      }

                      if (button) {
                        const props: any = {
                          className: `status ${onGetStatusClassName(value)}`,
                        };
                        if (bgColors[value]) {
                          props.style = {
                            backgroundColor: bgColors[value],
                            borderColor: bgColors[value],
                          };
                        }
                        return (
                          <td {...itemProps}>
                            <span {...props}>{value}</span>
                          </td>
                        );
                      }
                      //   else if (Status ){
                      //     return Status
                      //   }
                      if (row.name === "Seller") {
                        return (
                          <td {...itemProps}>
                            <Tooltip
                              title={
                                item.seller && item.seller !== "-"
                                  ? item.seller
                                  : ""
                              }
                            >
                              {value}
                            </Tooltip>
                          </td>
                        );
                      }
                      if (row.name === "Company") {
                        return (
                          <td {...itemProps}>
                            <Tooltip
                              title={
                                item.company && item.company !== "-"
                                  ? item.company
                                  : ""
                              }
                            >
                              {value}
                            </Tooltip>
                          </td>
                        );
                      }
                      if (row.name === "Facilitator") {
                        return (
                          <td {...itemProps}>
                            <Tooltip
                              title={
                                item.facilitator && item.facilitator !== "-"
                                  ? item.facilitator
                                  : ""
                              }
                            >
                              {value}
                            </Tooltip>
                          </td>
                        );
                      }
                      if (row.name === "Company Name") {
                        return (
                          <td {...itemProps}>
                            <Tooltip
                              title={
                                item.fullOrganization && item.fullOrganization !== "-"
                                  ? item.fullOrganization
                                  : ""
                              }
                            >
                              {value}
                            </Tooltip>
                          </td>
                        );
                      }
                      if (rating) {
                        return (
                          <td {...itemProps}>
                            <RatingStars value={value} />
                          </td>
                        );
                      }

                      return <td {...itemProps}>{value}</td>;
                    })}
                    {showInvoiceIcon && (
                      <td>
                        {" "}
                        <button
                          className="action"

                          // onClick={() =>
                          //   onToggleModal(modalTypes.QUESTION, item.id)
                          // }
                        >
                          <Tooltip title={item.invoice}>
                            <img src={article} alt="Unblock" />
                          </Tooltip>
                        </button>
                      </td>
                    )}
                    {actions && (
                      <td>
                        {isReplyAction && (
                          <>
                            <button
                              className="action"
                              title="Reply"
                              onClick={() =>
                                onToggleModal(modalTypes.REPLY, item.id)
                              }
                            >
                              <img src={ReplyIcon} alt="Reply" />
                            </button>
                          </>
                        )}
                        {/* {isEditAction && (
                          <button
                            className="action"
                            title="Edit"
                            onClick={() =>
                              onToggleModal(modalTypes.EDIT, item.id)
                            }
                          >
                            <img src={EditIcon} alt="Edit" />
                          </button>
                        )} */}
                        {isEditAction ? (
                          showInvoiceIcon ? (
                            <button
                              className="action"
                              title="Edit"
                              onClick={() =>
                                navigate(`/coaching-program/${item.id}`, {
                                  state: { path: "edit" },
                                })
                              }
                            >
                              <img src={EditIcon} alt="Edit" />
                            </button>
                          ) : (
                            <button
                              className="action"
                              title="Edit"
                              onClick={() =>
                                onToggleModal(modalTypes.EDIT, item.id)
                              }
                            >
                              <img src={EditIcon} alt="Edit" />
                            </button>
                          )
                        ) : null}
                        {isShowCancelReason &&
                          (selectedTab === "CANCELLED" ||
                            (selectedTab === "UPCOMING" &&
                              item.bookingStatus === "RESCHEDULE")) && (
                            <button
                              className="action"
                              title={
                                selectedTab === "UPCOMING"
                                  ? "Reschdule Reason"
                                  : "Cancel Reason"
                              }
                              onClick={() => {
                                if (selectedTab === "UPCOMING") {
                                  setReason(item.rescheduleReason);
                                  setType("Reschdule Reason");
                                } else {
                                  setType("Cancel Session Reason");
                                  setReason(item.cancelReason);
                                }

                                setIsCancelReason(true);
                              }}
                            >
                              <img src={cancelReason} alt="Edit" />
                            </button>
                          )}
                        {actionConfig.actions.delete && (
                          <button
                            className="action"
                            title="Delete"
                            onClick={() =>
                              onToggleModal(modalTypes.DELETE, item.id)
                            }
                          >
                            <img src={DeleteIcon} alt="Delete" />
                          </button>
                        )}
                        {actionConfig.actions.collapse && (
                          <button onClick={() => onExpandRow(i)}>
                            <img
                              src={isExpanded ? DropupIcon : DropDownIcon}
                              alt="Arrow"
                            />
                          </button>
                        )}

                        {isBlockUnblockAction && (
                          <>
                            {item.status !== "Inactive" && (
                              <button
                                className="action"
                                title="Active"
                                onClick={() =>
                                  onToggleModal(modalTypes.BLOCK, item.id)
                                }
                              >
                                <img src={BlockIcon} alt="Block" />
                              </button>
                            )}
                            {item.status !== "Active" && (
                              <button
                                className="action"
                                title="Inactive"
                                onClick={() =>
                                  onToggleModal(modalTypes.UNBLOCK, item.id)
                                }
                              >
                                <img src={UnblockIcon} alt="Unblock" />
                              </button>
                            )}
                          </>
                        )}
                        {/* {isBlockUnblockAction && (
                          <>
                            {item.status !== "Blocked" && (
                              <button
                                className="action"
                                title="Block"
                                onClick={() =>
                                  onToggleModal(modalTypes.BLOCK, item.id)
                                }
                              >
                                <img src={BlockIcon} alt="Block" />
                              </button>
                            )}
                            {item.status !== "Active" && (
                              <button
                                className="action"
                                title="Un Block"
                                onClick={() =>
                                  onToggleModal(modalTypes.UNBLOCK, item.id)
                                }
                              >
                                <img src={UnblockIcon} alt="Unblock" />
                              </button>
                            )}
                          </>
                        )} */}
                        {isMsgAction && (
                          <>
                            <button
                              className="action"
                              title="Question List"
                              // onClick={() =>
                              //   onToggleModal(modalTypes.QUESTION, item.id)
                              // }
                              onClick={() => {
                                setQueries(item.queries);
                                handleClickOpen();
                              }}
                            >
                              <img src={article} alt="Unblock" />
                            </button>
                          </>
                        )}
                        {isCancelAction &&
                          (item.bookingStatus === "BOOKED" ||
                            item.bookingStatus === "Session Booked" ||
                            item.bookingStatus === "RESCHEDULE") &&
                          selectedTab !== "CANCELLED" &&
                          new Date(item.startTime).getTime() >
                            new Date().getTime() && (
                            <>
                              <button
                                className="action"
                                title="Cancel"
                                onClick={() => {
                                  onToggleModal(modalTypes.CANCEL, item._id);
                                }}
                              >
                                <img src={BlockIcon} alt="Unblock" />
                              </button>
                            </>
                          )}
                        {isCalenderAction && selectedTab === "UPCOMING" && (
                          <>
                            <button
                              className="action"
                              title="Reschedule"
                              // onClick={() =>
                              //   onToggleModal(modalTypes.REPLY, item.id)
                              // }
                              onClick={() => {
                                // fetchSlotList()
                                setSessionData(item);
                                setCoachId(item.coachId);
                                onCalendarActionClick();
                              }}
                            >
                              <img src={calender} alt="Unblock" />
                            </button>
                          </>
                        )}

                        {/* {isCalenderAction && (
                          <>
                            <button
                              className="action"
                              title="List"
                              // onClick={() =>
                              //   onToggleModal(modalTypes.REPLY, item.id)
                              // }
                            >
                              <img src={calender} alt="Unblock" />
                            </button>
                          </>
                        )} */}
                      </td>
                    )}
                  </tr>
                  {isExpanded && <ExpandedComponent detail={item} />}
                </>
              );
            })}
            {!list?.length && !isLoading && !isTableLoading && (
              <tr>
                <td colSpan={8} className="text-center text-spacing">
                  No Record Found
                </td>
              </tr>
            )}

            {/* dots-loader */}
            <DotsLoaderView />
          </tbody>
        </table>
      </div>

      {open && (
        <div className="msg-popup">
          {/* Add your pop-up content here */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Questions
            </DialogTitle>
            {queries && queries.length ? (
              queries?.map((item: any) => {
                return (
                  <>
                    <DialogTitle id="alert-dialog-title">
                      {item.question}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {item.answer}
                      </DialogContentText>
                    </DialogContent>
                    <Divider />
                  </>
                );
              })
            ) : (
              <DialogTitle id="alert-dialog-title">
                {"No Questions Found"}
              </DialogTitle>
            )}
          </Dialog>
          {/* Close button */}
          {/* <button onClick={closeMsgPopup}>Close</button> */}
        </div>
      )}

      {isCancelReason && (
        <ShowCancelReasonModal
          isOpen={isCancelReason}
          onClose={() => setIsCancelReason(false)}
          reason={reason}
          type={type}
        />
      )}

      {actions && (
        <>
          <ModalView
            isOpen={openedModalType === modalTypes.DELETE}
            title={`Delete ${capitalizeHeading}`}
            header={`Are you sure want to delete the ${popupHeading}?`}
            wrapperClassName="delete-modal"
            onToggle={() => onToggleModal(modalTypes.DELETE)}
            onSubmit={onDeleteSubmit}
          />
          <ModalView
            isOpen={openedModalType === modalTypes.BLOCK}
            title={`Block ${capitalizeHeading}`}
            header={`Are you sure want to block the ${popupHeading}?`}
            wrapperClassName="block-modal"
            onToggle={() => onToggleModal(modalTypes.BLOCK)}
            onSubmit={onBlockSubmit}
          />
          <ModalView
            isOpen={openedModalType === modalTypes.UNBLOCK}
            title={`Unblock ${capitalizeHeading}`}
            header={`Are you sure want to unblock the ${popupHeading}?`}
            wrapperClassName="unblock-modal"
            onToggle={() => onToggleModal(modalTypes.UNBLOCK)}
            onSubmit={onUnBlockSubmit}
          />
          <EditComponent
            isOpen={openedModalType === modalTypes.EDIT}
            detail={list.find((rec) => rec.id === recordId)}
            onToggle={() => onToggleModal(modalTypes.EDIT)}
          />
          <ReplyComponent
            isOpen={openedModalType === modalTypes.REPLY}
            detail={list.find((rec) => rec.id === recordId)}
            onToggle={() => onToggleModal(modalTypes.REPLY)}
          />
          <CancelSessionComponent
            isOpen={openedModalType === modalTypes.CANCEL}
            detail={list.find((rec) => rec._id === recordId)}
            onToggle={() => onToggleModal(modalTypes.CANCEL)}
          />
        </>
      )}
    </>
  );
}

export default TableViewComponent;
